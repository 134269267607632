import { Homepage } from 'gis-website'
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
const LandingPage: NextPage = () => {
  return (
    <>
      <NextSeo
        title='Mango GIS - Professional, simple online GIS maps'
        description='Create and securely share maps your way. The accessible and user-friendly alternative to Esri for smart spenders.'
      />
      <Homepage />
    </>
  )
}

export default LandingPage
